import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
import { logout } from '../actions/authActions';
import { buttonReset } from '../actions/uiActions';
import ReactGA from 'react-ga';
export class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      subjectDynamicUrl: '',
      show: true,
      loading: true
    }
    this.selectTopicPage = this.selectTopicPage.bind(this);
  }
  static propTypes = {
    button: PropTypes.bool,
    authState: PropTypes.object.isRequired,
    buttonReset: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,

  };


componentDidMount(){
  ReactGA.pageview(window.location.pathname + window.location.search);
  demoAsyncCall().then(() => this.setState({ loading: false }));
  /*  var fullicon = document.getElementById("fullscreenicon");
  fullicon.classList.remove("fullScreenSL");
  fullicon.classList.add("fullScreenbtn"); */ 

};

/* fullscreen =() =>{
  toggleFullscreen();
}
 */
toggleFullscreen = (elem) => {
  console.log("in toggle");
elem = elem || document.documentElement;

if (!document.fullscreenElement && !document.mozFullScreenElement &&
  !document.webkitFullscreenElement && !document.msFullscreenElement) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  }
  var fas = document.getElementById("fullscreenicon");
  fas.classList.remove("fa-expand");
  fas.classList.add("fa-compress");
} else {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
  var fas = document.getElementById("fullscreenicon");
  fas.classList.remove("fa-compress");
  fas.classList.add("fa-expand");
}
}
  onLogout = (e) => {
    localStorage.setItem("env", '')
    localStorage.setItem('auth', '')
    localStorage.setItem('lessonid', '')
    localStorage.clear();
    e.preventDefault();
    this.props.buttonReset();
    this.props.logout();
  };

  selectTopicPage = (subject) => {
    localStorage.setItem("env", !!this.props.authState.user.env && JSON.stringify(this.props.authState.user.env))
    localStorage.setItem('auth', JSON.stringify(this.props.authState))
    this.setState({ redirect: true, subjectDynamicUrl: '/subjectSelection/' + subject + '' });
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return null; 
    }
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={this.state.subjectDynamicUrl} />;
    }
    if (!this.props.authState.isAuthenticated) {
      return <Redirect to="/" />
    }
    return (
      <div>
        <div className="d-flex" id="top-bar" style={{ width: "100%", height: "8%", backgroundColor: "#ffffff" }}>
          <div className="d-flex d-xl-flex align-items-center align-items-xl-center justify-content-between" id="logo-top" style={{ width: "50%", height: "100%" }}>
            <div id="logo-abc" style={{ width: "30%", height: "39px", backgroundImage: "url(../../img/ABCLogo.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div>
            <label id="headerclass" style={{ fontSize: "1.6vw", fontFamily: "Rajdhani", fontWeight: "bold", margin: "0px", color: "#290658" }}>Class {this.props.authState.user.class_no}</label>
          </div>
          <div className="d-flex d-xl-flex justify-content-end align-items-center justify-content-xl-end align-items-xl-center" id="Id-top-right" style={{ width: "50%", height: "100%" }}>
            <div className="d-flex d-xl-flex align-items-center align-items-xl-center" id="man-icon" style={{ width: "35%", height: "100%" }}>
              <div id="man-icon-image" style={{ width: "20%", height: "25px", backgroundImage: "url(../../img/avatar.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div>
              <div className="dropdown" style={{ backgroundColor: "rgba(255,255,255,0)", width: "50%", height: "60%" }}>
                <button className="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false" type="button" style={{ backgroundColor: "rgba(255,255,255,0)", width: "100%", height: "100%", color: "rgb(35,5,85)", fontSize: "1.3vw", fontWeight: "bold", fontFamily: "Rajdhani" }}>My Profile</button>
                <div
                  className="dropdown-menu" role="menu"><a className="dropdown-item" role="presentation" to="./login" style={{ fontWeight: "bold", fontSize: "1.5vw", fontFamily: "Rajdhani", color: "rgb(34,5,85)" }} onClick={this.onLogout}>Logout</a></div>
              </div>
            </div>
          </div>
        </div>
        <div id="main-div" style={{ width: "100%", height: "93.95%", position: "absolute" }}>
          <div id="img-bg" style={{ width: "100%", height: "100%", position: "absolute", backgroundImage: "url(../../img/newbg.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} />
          <div id="physics" style={{ width: "100%", height: "9%" }}></div>
          <div className="d-flex" id="physics-1" style={{ width: "100%", height: "11%" }}>
            <div className="d-flex align-items-start align-items-xl-start" id="physics-button-div-1" style={{ width: "50%", height: "100%" }}>
              <div id="empty-div-2" style={{ width: "72%", height: "100%" }}></div><button className="btn" id="Phy-btn-1" type="button" style={{ width: "21%", height: "43%", backgroundImage: "url(../../img/Physics_Button.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Physics')}></button></div>
          </div>
          <div className="d-flex" id="Bioandchem-div" style={{ width: "100%", height: "14%" }}>
            <div className="d-flex align-items-center align-items-xl-center" id="Chem-button-div-1" style={{ width: "50%", height: "100%" }}>
              <div id="empty-div-3" style={{ width: "13%", height: "100%" }}></div><button className="btn" id="Chem-btn-1" type="button" style={{ width: "21%", height: "34%", backgroundImage: "url(../../img/Chemistry_Button.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Chemistry')}></button></div>
            <div
              className="d-flex align-items-center align-items-xl-center" id="Bio-button-div-3" style={{ width: "50%", height: "100%" }}>
              <div id="empty-div-4" style={{ width: "40%", height: "100%" }}></div><button className="btn" id="Bio-btn-2" type="button" style={{ width: "21%", height: "34%", backgroundImage: "url(../../img/Biology_Button.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Biology')}></button></div>
          </div>
          <div className="d-inline-flex" id="Maths-div" style={{ width: "100%", height: "14%" }}>
            <div className="d-flex align-items-center align-items-xl-center" id="Maths-button-div-4" style={{ width: "50%", height: "100%" }}>
              <div id="empty-div-5" style={{ width: "63%", height: "100%" }}></div><button className="btn" id="Maths-btn-3" type="button" style={{ width: "21%", height: "34%", backgroundImage: "url(../../img/Maths_Button.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Mathematics')}></button></div>
            <div className="d-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="Maths-button-div-1" style={{ width: "50%", height: "100%" }}><button className="btn" id="Maths-btn-1" type="button" style={{ width: "21%", height: "34%", backgroundImage: "url(../../../../img/Library.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Library')}></button>
              <div id="empty-div-6" style={{ width: "47%", height: "100%" }}></div>
            </div>
          </div>
          <div className="d-flex" id="Social-4" style={{ width: "100%", height: "14%" }}>
            <div className="d-flex align-items-center align-items-xl-center" id="Empty-button-div-5" style={{ width: "44%", height: "100%" }}></div>
            <div className="d-flex align-items-center align-items-xl-center" id="Social-button-div-6" style={{ width: "56%", height: "100%" }}><button className="btn" id="Social-btn-5" type="button" style={{ width: "19%", height: "34%", backgroundImage: "url(../../img/Social_Button.png)", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "sticky", backgroundColor: "rgba(255,255,255,0)" }} onClick={() => this.selectTopicPage('Social Studies')}></button>
              <div
                id="empty-div-7" style={{ width: "63%", height: "100%" }}></div>
            </div>
          </div>
          <div id="Empty-5" style={{ width: "100%", height: "36%" }}></div>

        </div>
       <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenbtn" type="button" onClick={() => this.toggleFullscreen()}  ></button> 
    </div>
    
    


    )
  }
}
const mapStateToProps = (state) => ({
  button: state.ui.button,
  authState: state.auth
});
function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}
export default connect(mapStateToProps, { logout, buttonReset })(Profile);
