import * as React from 'react';
    import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);

    export class SampleBase extends React.PureComponent {
        rendereComplete() {
            /**custom render complete function */
        }
        componentDidMount() {
            setTimeout(() => {
            this.rendereComplete();
            console.log( document.getElementById('diagram_diagramLayer_div').style.width)
            if(document.getElementById('diagram_diagramLayer_div') != null){
                localStorage.setItem('mind-width', document.getElementById('diagram_diagramLayer_div').style.width);
              }
        },3000);
          }
        }
    