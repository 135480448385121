import React, { Component} from 'react'
import Register from './Register';
import { connect } from "react-redux"; // API to connect component state to redux store
import PropTypes from "prop-types";
import { buttonClicked,isLoading } from "../actions/uiActions";
import { login } from "../actions/authActions";
import {Redirect} from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';
//import { FullScreen, useFullScreenHandle } from "react-full-screen";
class Login extends Component {

  /* state = {
    email: "",
    password: "",
    msg: ""
  } */
constructor(props){
  super(props)
  this.state = {
    email: "",
    password: "",
    msg: "",
    authStatus:false
  }
}
  static propTypes = {
    buttonClicked: PropTypes.func.isRequired,
    isLoading: PropTypes.func.isRequired,
    button: PropTypes.bool,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    status: PropTypes.object.isRequired,
    loading: PropTypes.bool
  };

  componentDidMount() {
    
    this.props.buttonClicked();
    ReactGA.pageview(window.location.pathname + window.location.search);
    /*  var fullicon = document.getElementById("fullscreenicon");
    fullicon.classList.remove("fullScreenbtn");
    fullicon.classList.add("fullScreenSL");  */
    console.log(document.referrer);
    
}

componentDidUpdate(prevProps) {
      const status = this.props.status;

     if (status !== prevProps.status) {

      if (status.id === "LOGIN_FAIL") {
        this.setState({ msg: status.statusMsg });
      }
    }
};


onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

onSubmit = (e) => {
    e.preventDefault();
    const { email, password} = this.state;

    const user = { email, password};
    this.props.isLoading();
    this.props.login(user);
    setTimeout(() => {
      console.log(this.props);
      if(this.props.isAuthenticated){
        this.setState({
          authStatus:true
        })
      }
    }, 3000);
   
  };
 
   toggleFullscreen = (elem) => {
    console.log("in toggle");
  elem = elem || document.documentElement;

  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-expand");
    fas.classList.add("fa-compress");
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-compress");
    fas.classList.add("fa-expand");
  }
} 

  render() {
    //const handle = useFullScreenHandle();
    if(this.state.authStatus) {
      return <Redirect to="/profile" />
    }
    let className = 'divStyle';
    if (!this.props.button) {
      className = 'formStyle';
    }
    return (
      
      <div className={className}>
       {/*  <div className="preloader">
        <Loader
         className="preloader-img"
         type="Puff"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={2000} //2 secs
 
      /></div> */}
       <div style={{position: "absolute",width:"100%", height:"100%",backgroundImage:"url(../../img/BG.jpg)",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center"}} />

       <Switch>
              <Route exact path ="/register" component={Register}/>
            </Switch>
       <form onSubmit={this.onSubmit} >
                  
     <div style={{height: "100%",position: "absolute",width: "100%"}}>
        <div style={{width: "50%",height: "100%",marginLeft: "15%"}}>
          <div style={{height: "35%"}}></div>
          <div className="d-flex d-xl-flex flex-column justify-content-center align-items-end justify-content-xl-center align-items-xl-end" 
            style={{width: "30%",height: "25%"}}>
             {this.state.msg ? (
              <span  style={{color:"red"}}>{this.state.msg}</span>
            ) : null}
            <label className="d-xl-flex align-items-xl-center" 
              style={{width: "100%",height: "25%",fontFamily: "Rajdhani, sans-serif",fontWeight: "bold",fontSize: "0.9vw",margin: "0p"}}>Username / Mobile No.</label>
            <input className="border rounded border-secondary shadow mb-3" type="email"
              name="email"
              id="email"
              size="lg"
              placeholder="you@youremail.com"
              onChange={this.onChange} 
            style={{backgroundColor: "rgb(237,237,237)",width: "100%",height: "25%",fontSize:"0.9vw"}} />
              <div style={{height: "20%",width: "100%"}}></div>
        <label className="d-xl-flex align-items-xl-center" 
          style={{width: "100%",height: "25%",fontFamily: "Rajdhani,sans-serif",	fontWeight: "700",fontSize: "0.9vw",margin: "0px",color: "rgb(48,52,57)"}}>Password</label>
        <input className="border rounded border-secondary shadow mb-3" type="password" 
          name="password"
          id="password"
          size="lg"
          placeholder="Enter your Password"
          onChange={this.onChange}
          style={{backgroundColor: "rgb(237,237,237)",width: "100%",height: "25%",fontSize:"0.9vw"}} />
			</div>
            
      <div className="d-flex d-xl-flex justify-content-center justify-content-xl-center" 
        style={{width: "30%",height: "4%",marginTop: "2%"}}>
        <button className="btn" id="login-submit" type="submit" 
          style={{backgroundColor: "rgba(0,123,255,0)",backgroundImage: "url(../../img/Login_Button.jpg)",	backgroundPosition: "center",backgroundSize: "contain",backgroundRepeat: "no-repeat",width: "60%",height: "100%"}}>
				</button>
        
			</div>
    </div>
</div> </form>
<footer className="d-flex flex-row" style={{position: "absolute",bottom: "0",right: "0",textAlign: "right",marginRight:"3%"}}>

  <span id="s2b_siteseal" rel="comodo"><a target="_blank" href="https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=CL1&v_search=http://aimxcel.in/&x=6&y=5"><img src="../../img/sectigo_trust_seal_md.png" style={{width: "30%", height:"auto"}}/></a>
  
  </span>
  </footer>
  {/* <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenSL" type="button" ></button> */}
  <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenSL" type="button" onClick={()=>this.toggleFullscreen()}></button>
      </div>
     
    )
  }
}

const mapStateToProps = (state) => ({ 
  button: state.ui.button, 
  isAuthenticated: state.auth.isAuthenticated,
  status: state.status,
  loading: state.ui.loading
});
function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 10500));
}
export default connect(mapStateToProps,{ login, isLoading, buttonClicked })(Login);
