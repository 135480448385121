import React , { Component }  from 'react';
import Login from './components/Login';
import Profile from './components/Profile';
import { Route, Switch} from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './store';
import { SubjectSelection } from './components/SubjectSelection';
import { SelectedLesson } from './components/SelectedLesson';
import { Library } from './components/Library';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import WhiteBoardPage from './white-board/WhiteBoardPage';
import ReactGA from 'react-ga';
//import { FullScreen, useFullScreenHandle } from "react-full-screen";
ReactGA.initialize('UA-174198887-01');

class App extends Component {
  
  state = {
    loading: true
   
  };
  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
    //const handle = useFullScreenHandle();
   
    
  }
  fullscreen = () => {
     var button = document.querySelector('.expandframe');
    button.addEventListener('click', this.fullScreen());
    // when you are in fullscreen, ESC and F11 may not be trigger by keydown listener. 
    // so don't use it to detect exit fullscreen
    document.addEventListener('keydown', function (e) {
        console.log('key press' + e.keyCode);
    });
    // detect enter or exit fullscreen mode
    /*   document.addEventListener('webkitfullscreenchange', this.fullscreenChange());
      document.addEventListener('mozfullscreenchange', this.fullscreenChange()); */
    document.addEventListener('fullscreenchange', this.fullscreenChange());
    //document.addEventListener('MSFullscreenChange', this.fullscreenChange()); 
    /* document.getElementById('fullscreenicon').addEventListener('click', function() {
      this.toggleFullscreen();
    });
    
    document.getElementById('fullscreenicon').addEventListener('click', function() {
      this.toggleFullscreen(this);
    }); */
  //  this.toggleFullscreen();


}

 fullScreen() {
    // check if fullscreen mode is available
    if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled) {

        // which element will be fullscreen
       // var iframe = document.querySelector('#area-activity iframe');
       var iframe = document.documentElement;
        // Do fullscreen
        iframe.requestFullscreen();
        /*  if (iframe.requestFullscreen) {
             iframe.requestFullscreen();
         } else if (iframe.webkitRequestFullscreen) {
             iframe.webkitRequestFullscreen();
         } else if (iframe.mozRequestFullScreen) {
             iframe.mozRequestFullScreen();
         } else if (iframe.msRequestFullscreen) {
             iframe.msRequestFullscreen();
         } */
    }
    else {
        document.querySelector('.error').innerHTML = 'Your browser is not supported';
    }
}

fullscreenChange() {
    if (document.fullscreenEnabled ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement) {
        console.log('enter fullscreen');
    }
    else {
        console.log('exit fullscreen');
    }
    var iframe = document.documentElement
    //  iframe.src = iframe.src;
} 
 /*toggleFullscreen(elem) {
  elem = elem || document.documentElement;

  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-expand");
    fas.classList.add("fa-compress");
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-compress");
    fas.classList.add("fa-expand");
  }
} */


  render () {
    
    const { loading } = this.state;
   // console.log("url: "+window.location.href+" and path: "+window.location.pathname+"and origin: "+window.location.origin);
    if(loading) { // if your component doesn't have to wait for an async action, remove this block 
      return null; // render null when app is not ready
    }
    /* const isSelectedLessonActive = matchPath(this.props.location.pathname,'/selectedLesson/'); */
    return (
      
      <Provider store={store}>
            <Switch>
            
            <Route exact path ="/" component={Login}/>
              <Route exact path ="/profile" component={Profile}/>
              <Route exact path ="/subjectSelection/:subject" component={SubjectSelection} />
              <Route exact path ="/selectedLesson/:topic" component={SelectedLesson} />
              <Route exact path ="/selectedLibraryLesson/:topic" component={Library} />
              <Route exact path ="/whiteBoard" component={WhiteBoardPage} />
             
            </Switch>  
             {/* <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenbtn" type="button" onClick={handler}></button> */}        
      </Provider>
      
      
    );
}
}
/* function handler() {
  
    fscreen.addEventListener('fullscreenchange', handler, false);
    fscreen.requestFullscreen(this);
   
  if (fscreen.fullscreenElement !== null) {
    console.log('Entered fullscreen mode');
  } else {
    console.log('Exited fullscreen mode');
  }
 } */
function demoAsyncCall() {
  console.log("in demoasync");
  return new Promise((resolve) => setTimeout(() => resolve(), 2000));
}
export default App;
