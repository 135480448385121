import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import PropTypes from "prop-types";
import './style.css';
import { logout } from '../actions/authActions';
import { buttonReset } from '../actions/uiActions';
import store from '../store';
import { isAuth } from '../actions/authActions';
export class Library extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            data: {},
            auth: JSON.parse(JSON.stringify(localStorage.getItem("auth"))),
            subject: this.props.match.params.topic.split('-')[0],
            lessonid: localStorage.getItem('lessonid'),
            backurl: '/subjectSelection/' + this.props.match.params.topic.split('-')[0],
            questions: [],
            lexicon: [],
            lexicon_image: '',
            model: "none",
            mindmap: [],
            env: JSON.parse(localStorage.getItem("env"))
        }

    }
    static propTypes = {
        button: PropTypes.bool,
        authState: PropTypes.object.isRequired,
        buttonReset: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
    };
    componentDidMount() {
        // Check if session cookie is present
        store.dispatch(isAuth());
        const user = JSON.parse(this.state.auth);
        store.dispatch(isAuth());
       /*  var fullicon = document.getElementById("fullscreenicon");
        fullicon.classList.remove("fullScreenSL");
        fullicon.classList.add("fullScreenbtn"); */
        fetch("api/lesson/findLessonBySubjectAndLesson/" + user.user.class_no + "/" + this.state.subject.slice(0, 1).toUpperCase() + this.state.subject.slice(1, this.state.subject.length) + "/" + this.state.lessonid)
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data[0] })
                console.log(data);

            });
    }

    static propTypes = {
        button: PropTypes.bool,
        isAuthenticated: PropTypes.bool,
    };

    onLogout = (e) => {
        e.preventDefault();
        this.props.buttonReset();
        this.props.logout();
    };

    toggleFullscreen = (elem) => {
        console.log("in toggle");
      elem = elem || document.documentElement;
    
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        var fas = document.getElementById("fullscreenicon");
        fas.classList.remove("fa-expand");
        fas.classList.add("fa-compress");
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        var fas = document.getElementById("fullscreenicon");
        fas.classList.remove("fa-compress");
        fas.classList.add("fa-expand");
      }
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/topicSelection' />;
        }
        const { data } = this.state;
        console.log("INTRO_URL = " + this.state.env.INTRO_URL)
        return (
            <div style={{ height: "100vh" }}>
                <div className="d-flex d-xl-flex align-items-center align-items-xl-center topbar" id="div-top"
                    style={{ height: "7%", paddingTop: "0.25%", backgroundColor: "#0f0f55" }}>
                    <div style={{ height: "100%", width: "5%", backgroundColor: "#0f0f55" }}>
                        <Link to={this.state.backurl}>
                            <button className="btn d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="div-top-btn-back" type="button"
                                style={{
                                    width: "100%", height: "100%", backgroundColor: "rgba(0,123,255,0)", backgroundImage: "url(../../img/UI_Icon_ArrowLeft.png)", backgroundPosition: "center",
                                    backgroundSize: "contain", backgroundRepeat: "no-repeat", padding: "0px"
                                }} >
                            </button>
                        </Link>
                    </div>
                    <div className="d-flex d-xl-flex align-items-center align-items-xl-center" style={{ height: "100%", width: "75%", paddingLeft: "1%", backgroundColor: "#ffffff" }}>
                        <label className="d-flex d-xl-flex align-items-center justify-content-xl-start align-items-xl-center" id="div-top-labelpath"
                            style={{
                                width: "100%", height: "80%",
                                color: "rgb(15,15,85)", fontSize: "1.5vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "600", margin: "0px"
                            }}>Class {data.class_no} - {data.subject} - {data.lesson}.{data.lesson_name}
                        </label>
                    </div>
                    <div className="d-flex align-items-center" style={{ height: "100%", width: "20%", backgroundColor: "#0f0f55" }}>
                        <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="div-top-labelbtn"
                            style={{ height: "100%", color: "rgb(255,255,255)", fontSize: "1.5vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold", width: "100%", margin: "0px" }}>Activity
               </label>
               <Link to="/">
                    <button type="button" className="fas fa-power-off" style={{ color:"#fff",border: "none", backgroundColor: "transparent",/* height:"5%", width: "3%", */ opacity: "1", /* position: "absolute",float:"right", */ right:"0" ,top: "0", fontSize: "1.5vw" }}></button>
                </Link>
                    </div>
                </div>

                <div className="embed-responsive embed-responsive-1by1" id="area-activity" style={{ width: "100%", height: "93%" }}>
                    <iframe title="title" className="embed-responsive-item" src={this.state.env.ACTIVITY_URL + data.activity_url} allowFullScreen=""></iframe>
                    <i class="fas fa-expand" style={{ position: "absolute", fontSize: "24px" }}></i>
                </div>
                <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenbtn" type="button" onClick={()=>this.toggleFullscreen()}></button>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({ //Maps state to redux store as props
    button: state.ui.button,
    authState: state.auth
});

export default connect(mapStateToProps, { logout, buttonReset })(Library);
