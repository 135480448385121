import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import PropTypes from "prop-types";
import store from '../store';
import { isAuth } from '../actions/authActions';
import { logout } from '../actions/authActions';
import { buttonReset} from '../actions/uiActions';
import ReactGA from 'react-ga';
export class SubjectSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoaded: false,
      data: [],
      auth: JSON.parse(JSON.stringify(localStorage.getItem("auth"))),
      subject: this.props.match.params.subject,
      topicDynamicUrl: '',
      class_no: 0,
      env: JSON.parse(localStorage.getItem("env")),
      hasError: false,
      status: false
    }
    this.selectedLesson = this.selectedLesson.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    store.dispatch(isAuth());
    this.setState({ hasError: true });
  }

     

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // Check if session cookie is present
    /* var fullicon = document.getElementById("fullscreenicon");
    fullicon.classList.remove("fullScreenSL");
    fullicon.classList.add("fullScreenbtn"); */

    // Check if session cookie is present


    store.dispatch(isAuth());
    if (this.state.auth === null) {
      this.setState({ hasError: true })
    } else {
      const user = JSON.parse(this.state.auth);
      this.setState({ class_no: user.user.class_no })
      fetch("api/lesson/findLessonBySubject/" + user.user.class_no + "/" + this.state.subject)
        .then(response => response.json())
        .then(data => {
          this.setState({ data: data })
        });
    }
  }

  static propTypes = {
    button: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
     buttonReset: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired 
  };

  /* onLogout = (e) => {
    e.preventDefault();
    this.props.buttonReset();
    this.props.logout();
  }; */

  selectedLesson = (lesson_name, lessonid, status) => {
    if (status === "No") {
      this.setState({ status: true });
      setTimeout(() => {
        this.setState({ status: false });
      }, 3000);
      return false;
    }
    else {
      var paths = this.props.location.pathname.split('/')[2];
      localStorage.setItem('lessonid', lessonid)
      if (paths.toLowerCase() !== 'library') {
        this.setState({ redirect: true, topicDynamicUrl: '/selectedLesson/' + paths + '-' + lesson_name.toLowerCase().replace(' ', '-') });
      } else {
        this.setState({ redirect: true, topicDynamicUrl: '/selectedLibraryLesson/' + paths + '-' + lesson_name.toLowerCase().replace(' ', '-') });
      }
    }
  };
  onLogout = (e) => {
    localStorage.setItem("env", '')
    localStorage.setItem('auth','')
    localStorage.setItem('lessonid','')
    localStorage.clear();
    e.preventDefault();
    this.props.buttonReset();
    this.props.logout();
  };
  
  toggleFullscreen = (elem) => {
    console.log("in toggle");
  elem = elem || document.documentElement;
  
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-expand");
    fas.classList.add("fa-compress");
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    var fas = document.getElementById("fullscreenicon");
    fas.classList.remove("fa-compress");
    fas.classList.add("fa-expand");
  }
  }

  render() {

    const { redirect } = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect to="/profile" />
    }
    if (redirect) {
      return <Redirect to={this.state.topicDynamicUrl} />;
    }
    if (this.state.hasError) {
      return <Redirect to="/" />;
    }
   /*  if(!this.props.authState.isAuthenticated) {
      return <Redirect to="/" />
    } */
    const { data } = this.state;
    var bgurl = "../../img/" + this.state.class_no + this.state.subject.replace(' ', '') + ".jpg";
    /* const {user} = this.props.authState; */
    return (
      <div>
        <div id="forbg" style={{ width: "100%", height: "100%", backgroundImage: "url(" + bgurl + ")", position: "absolute", backgroundPosition: "center", backgroundSize: "cover" }}></div>
        <div style={{ height: "20%", paddingTop: "0.5%", paddingLeft: "0.5%" }}>
          <Link to="/profile">
            <button className="btn" type="button" style={{
              position: "absolute", backgroundColor: "rgb(0,123,255,0)", backgroundRepeat: "no-repeat", backgroundImage: "url(../../img/UI_Icon_ArrowLeft.png)",
              backgroundPosition: "center", backgroundSize: "contain", width: "3%", height: "5%"
            }} >
            </button>
          </Link>
        <Link to="/">
          <button type="button" className="fas fa-power-off" style={{ color:"#fff",border: "none", backgroundColor: "transparent",height:"5%", width: "3%", opacity: "1", position: "absolute",float:"right", right:"0" ,top: "0", fontSize: "1.5vw" }}></button>
        </Link>
        </div>
        <div style={{ width: "35%", height: "50%", marginRight: "auto", marginLeft: "11%", marginTop: "4%" }}>
          <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
            style={{ position: "relative", width: "100%", height: "10%", color: "rgb(255,255,255)", fontSize: "1.5vw", fontFamily: "Rajdhani, sansSerif", fontWeight: "600" }}>
          </label>
          <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" style={{
            width: "100%", height: "8%", margin: "0px",
            backgroundColor: "transparent", position: "relative", color: "#D040BC", fontSize: "1.25vw", fontFamily: "Rajdhani, sansserif", fontWeight:
              "600"
          }}>The lesson number matches the textbook lesson number
		</label>
          <div className="dropdown d-inline-block" style={{ width: "100%", height: "10%" }}>
            <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false" type="button" style={{
              width: "100%", height: "100%",
              backgroundColor: " rgb(255,255,255)", color: " rgb(11,11,11)", fontSize: "1.5vw", fontFamily: "Rajdhani, sansserif", fontWeight: "600", padding: "0px"
            }}>Select your topic from the list
			</button>
            <div className="dropdown-menu scrollable-menu" role="menu" style={{ width: "100%", height: "auto" }}>

              {data.map((element, index) => {
                if (element.status !== "No") {
                  return (<a key={index} className="dropdown-item lessonmenu" role="presentation" style={{ fontWeight: "bold" }} onClick={() => this.selectedLesson(element.lesson_name, element.lesson, element.status)}>{element.lesson_leadzero + '. ' + element.lesson_name}</a>)
                }
                else {
                  return (<a key={index} className="dropdown-item lessonmenu" role="presentation" style={{ fontWeight: "bold", color: "grey" }} onClick={() => this.selectedLesson(element.lesson_name, element.lesson, element.status)}>{element.lesson_leadzero + '. ' + element.lesson_name}</a>)
                }
              })
              }
            </div>

          </div>
        </div>
        <div className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" style={{ height: "10%" }}>
          {this.state.status === true &&
            <div className="d-flex justify-content-center align-items-center" style={{
              position: "absolute",
              width: "50%",
              height: "30%",
              backgroundColor: "#d040a8",
              left: "25%",
              top: "25%"
            }}>
              <label style={{
                color: "white",
                fontSize: "2vw",
                fontWeight: "bold",
                fontFamily: "Rajdhani"
              }}>This is a demo instance. Only sample lessons are enabled</label></div>
          }
        </div>
        <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenbtn" type="button" onClick={() => this.toggleFullscreen()} ></button>
      </div >
    )
  }
}
const mapStateToProps = (state) => ({ //Maps state to redux store as props
  button: state.ui.button,
  isAuthenticated: state.auth.isAuthenticated,
  authState: state.auth
});

export default connect(mapStateToProps,{ logout, buttonReset })(SubjectSelection);
