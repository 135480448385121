import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import PropTypes from "prop-types";
import './style.css';
import { logout } from '../actions/authActions';
import { buttonReset } from '../actions/uiActions';
import store from '../store';
import { isAuth } from '../actions/authActions';
import { MindMap } from '../common/index';
import ReactGA from 'react-ga';
//import Dropdown from 'react-dropdown';
//import 'react-dropdown/style.css';
export class SelectedLesson extends Component {

    constructor(props) {

        super(props);
        this.state = {
            redirect: false,
            data: {},
            auth: JSON.parse(JSON.stringify(localStorage.getItem("auth"))),
            subject: this.props.match.params.topic.split('-')[0],
            lessonid: localStorage.getItem('lessonid'),
            backurl: '/subjectSelection/' + this.props.match.params.topic.split('-')[0],
            questions: [],
            lexicon: [],
            lexicon_image: '',
            model: "none",
            mindmap: [],
            env: JSON.parse(localStorage.getItem("env")),
            pdfurl: '',
            showLoad: false,
            whiteBoardUrl: false,
            hasError: false,
            loading: true,
            isOpen: false,
            labelItem: null,
            typeDropdown: null,
            selectValue: null, 
            filterData: ['English', 'Telugu', 'Hindi']
        }
        this.showDiv = this.showDiv.bind(this);
        this.gotoWhiteBoardPage = this.gotoWhiteBoardPage.bind(this);
        
    }
    
    static propTypes = {
        button: PropTypes.bool,
        authState: PropTypes.object.isRequired,
        buttonReset: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        list: PropTypes.array.isRequired,
    };
    static defaultProps = {
        list: []
      };
    componentDidCatch(error, info) {
        // Display fallback UI
        store.dispatch(isAuth());
        console.log(this.props);
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }
    componentDidUpdate() {
        //  this.setState({ showLoad: false });
    };
    onChangeHandle = (name) => {
        this.setState({ selectValue: name });
        if(name == "Hindi"){
            this.hindiAudio();
        }
        else if(name == "English"){
            this.englishAudio();
        }
        else if(name == "Telugu"){
            this.teluguAudio();
        }
        else{

        }
      };
    //start of dropdown properties
    /* componentWillMount() {
        const { label } = this.props.list[0];
        let firstItem = null;    
        if (typeof label != 'undefined') {
          this.checkType(false);
          firstItem = label;
        } else {
          this.checkType(true);
          firstItem = this.props.list[0];
        }        
        this.setState({
            labelItem: firstItem
        });    
      }
      checkType = (value) => {
        this.setState({
            typeDropdown: value
        });    
      };
      showDropdown = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
      };
      hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
      };
      chooseItem = (value) => {    
        if (this.state.labelItem !== value) {
          this.setState({
            labelItem: value      
          })
        }    
      };
      renderDataDropDown = (item, index) => {    
        const {value, label} = this.state.typeDropdown ? {value: index, label: item} : item;    
        return (
          <li
            key={index}
            value={value}
            onClick={() => this.chooseItem(label)}
          >
            <a>{label}</a>
          </li> 
        )
      };
      render () {
        const { list } = this.props;    
        return (
          <div className={`dropdown ${this.state.isOpen ? 'open' : ''}`}>
            <button className="dropdown-toggle" type="button" onClick={this.showDropdown}>
              {this.state.labelItem}
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu">
              {list.map(this.renderDataDropDown)}
            </ul>
        </div>
        )
      } */
      //end of dropdown properties

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);

       /*  var fullicon = document.getElementById("fullscreenicon");
        fullicon.classList.remove("fullScreenbtn");
        fullicon.classList.add("fullScreenSL"); */
        // Check if session cookie is present
        console.log("entered into compounddidmount")

        // Check if session cookie is present
        console.log("entered into compounddidmount")

        

        

        store.dispatch(isAuth());
        demoAsyncCall().then(() => this.setState({ loading: false }));
        if (this.state.auth === null || this.state.env === null) {
            this.setState({ hasError: true })
        } else {
            const user = JSON.parse(this.state.auth);
            store.dispatch(isAuth());
            this.setState({ showLoad: true });
            fetch("api/lesson/findLessonBySubjectAndLesson/" + user.user.class_no + "/" + this.state.subject + "/" + this.state.lessonid)
                .then(response => response.json())
                .then(data => {
                    !!data[0] && this.setState({ data: data[0], mindmap: JSON.parse(JSON.stringify(data[0].mindmap_url)) })
                    !!data[0] && this.findCriticalThinkingQuestions(data[0].critical_thinking_id);
                    !!data[0] && this.findLexiconData(data[0].lexicon_id);
                   

                });

        }

    }
    findLexiconData(lexicon_id) {
        fetch("api/lexicon/findLexiconById/" + lexicon_id)
            .then(response => response.json())
            .then(data => {
                this.setState({ lexicon: data })
            });
    }

    findCriticalThinkingQuestions(critical_thinking_id) {
        fetch("api/critical-thinking/findQuestionsById/" + critical_thinking_id)
            .then(response => response.json())
            .then(data => {
                this.setState({ questions: data })
            });
    }
    static propTypes = {
        button: PropTypes.bool,
        isAuthenticated: PropTypes.bool,
    };

    onLogout = (e) => {
        localStorage.setItem("env", '')
        localStorage.setItem('auth','')
        localStorage.setItem('lessonid','')
        localStorage.clear();
        e.preventDefault();
        this.props.buttonReset();
        this.props.logout();
    };
    modelImage = (image) => {

        !!this.state.env.LEXICON_IMG_URL && this.setState({ model: "block", lesson_image: this.state.env.LEXICON_IMG_URL + image.replace(/ /g, '%20') })
    }

    closeModel = () => {
        this.setState({ model: "none", lesson_image: '' });
    }
   

    /* fullscreen = () => {
        var button = document.querySelector('.expandframe');
        button.addEventListener('click', this.fullScreen());
        document.addEventListener('keydown', function (e) {
            console.log('key press' + e.keyCode);
        });
        document.addEventListener('fullscreenchange', this.fullscreenChange());
    }

    fullScreen() {
        // check if fullscreen mode is available
        if (document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled) {

            // which element will be fullscreen
            var iframe = document.querySelector('#area-activity iframe');
            // Do fullscreen
            iframe.requestFullscreen();
        }
        else {
            document.querySelector('.error').innerHTML = 'Your browser is not supported';
        }
    }

    fullscreenChange() {
        if (document.fullscreenEnabled ||
            document.webkitIsFullScreen ||
            document.mozFullScreen ||
            document.msFullscreenElement) {
            console.log('enter fullscreen');
        }
        else {
            console.log('exit fullscreen');
        }
        var iframe = document.querySelector('iframe');
    } */
    showDiv = (id) => {
        var video = document.getElementById("area-video");
        var divs = document.querySelectorAll(".playarea");
        for (var i = 0; i < divs.length; i++) {
            divs[i].style.display = "none";
        }
        var divToShow = document.getElementById(id);
        divToShow.style.display = "block";

        switch (id) {
            case 'areadiv2':
                document.getElementById('div-top-labelbtn').innerHTML = "Video";
                document.getElementById('ddownid').style.display="block";
                video.play();
                this.setState({ pdfurl: '' })
                
                break;
            case 'areadiv3':
                document.getElementById('div-top-labelbtn').innerHTML = "Mind map";
                document.getElementById('ddownid').style.display="none";
                video.pause();
                !!this.state.env.MINDMAP_PDF_URL && this.setState({ pdfurl: this.state.env.MINDMAP_PDF_URL + this.state.data.mindmap_pdf })
                break;
            case 'areadiv4':
                document.getElementById('div-top-labelbtn').innerHTML = "Lexicon";
                document.getElementById('ddownid').style.display="none";
                video.pause();
                !!this.state.env.LEXICON_PDF_URL && this.setState({ pdfurl: this.state.env.LEXICON_PDF_URL + this.state.data.lexicon_pdf })
                break;
            case 'areadiv5':
                console.log(this.state.data);
                console.log(this.state.env)
               // setInterval(function() {
                    document.querySelector('iframe.reload').setAttribute('src', !!this.state.data.activity_url && !!this.state.env && this.state.env.ACTIVITY_URL ? this.state.env.ACTIVITY_URL + this.state.data.activity_url : '');
                 // }, 5000);
                document.getElementById('div-top-labelbtn').innerHTML = "Activity";
                document.getElementById('ddownid').style.display="none";
                video.pause();
                
                this.setState({ pdfurl: '' })
                break;
            case 'areadiv6':
                document.getElementById('div-top-labelbtn').innerHTML = "Critical Thinking";
                document.getElementById('ddownid').style.display="none";
                video.pause();
                this.setState({ pdfurl: '' })
                break;
            default:
                document.getElementById('div-top-labelbtn').innerHTML = "Introduction";
                document.getElementById('ddownid').style.display="none";
                video.pause();
                this.setState({ pdfurl: '' })

        }
    };
    gotoWhiteBoardPage = () => {
        this.setState({ whiteBoardUrl: true })
    }
    toggleFullscreen = (elem) => {
        elem = document.querySelector('#area-activity iframe');
        console.log("in toggle");
      elem = elem || document.documentElement;
      if(document.getElementById('diagram_diagramLayer_svg') != null){
        document.getElementById('diagram_diagramLayer_svg').style.width = localStorage.getItem('mind-width');
      }
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        /* var fas = document.getElementById("fullscreenicon");
        fas.classList.remove("fa-expand");
        fas.classList.add("fa-compress"); */
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        /* var fas = document.getElementById("fullscreenicon");
        fas.classList.remove("fa-compress");
        fas.classList.add("fa-expand"); */
      }
    }
    teluguAudio = () =>{
        var video = document.getElementById("area-video");
        var videotime = video.currentTime;
        for (var i = 0; i < video.audioTracks.length; i += 1) {
            video.audioTracks[i].enabled = false;
          }
          console.log("video time"+video.currentTime);
          video.audioTracks[1].enabled=true;
          video.currentTime = videotime+0.05;
    }
    englishAudio = () =>{
        var video = document.getElementById("area-video");
        var videotime = video.currentTime;
        for (var i = 0; i < video.audioTracks.length; i += 1) {
            video.audioTracks[i].enabled = false;
          }
          video.audioTracks[0].enabled=true;
          video.currentTime = videotime+0.05;
    }
    hindiAudio = () =>{
        var video = document.getElementById("area-video");
        var videotime = video.currentTime;
        for (var i = 0; i < video.audioTracks.length; i += 1) {
            video.audioTracks[i].enabled = false;
          }
          video.audioTracks[2].enabled=true;
          video.currentTime = videotime+0.05;
    }
    handleKeys = (event) => {
        var videodiv = document.getElementById("areadiv2");
        if(videodiv.style.display == "block"){
        if(event.key == 1){
          console.log('pressed 1')
          this.englishAudio();
        }
        else if(event.key == 2){
            console.log('pressed 2')
            this.teluguAudio();
        }
        else if(event.key == 3){
            console.log('pressed 3')
            this.hindiAudio();
        }
        else{

        }
        
      }
    }
    /* const options = [
        'one', 'two', 'three'
      ] */
    render() {
        const { loading } = this.state;
        //const defaultOption = options[0];
       
        var linkboard= "https://whiteboard.aimxcel.in/board.html?"+this.state.data.lexicon_id;
        if (loading) { // if your component doesn't have to wait for an async action, remove this block 
            return null; // render null when app is not ready
        }
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/topicSelection' />;
        }
        if (this.state.whiteBoardUrl) {
            return <Redirect to='/whiteBoard' />;
        }
        if (this.state.hasError || this.state === null || this.state.auth === null || this.state.env === null) {
            return <Redirect to="/" />;
        }
        const { data } = this.state;
        return (
            <div style={{ height: "100vh" }} onKeyDown={this.handleKeys}>
                <div className="d-flex d-xl-flex align-items-center align-items-xl-center topbar" id="div-top"
                    style={{ height: "7%", paddingTop: "0.25%", backgroundColor: "#0f0f55" }}>
                    <div style={{ height: "100%", width: "5%", backgroundColor: "#0f0f55" }}>
                        <Link to={this.state.backurl}>
                            <button className="btn d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="div-top-btn-back" type="button"
                                style={{
                                    width: "100%", height: "100%", backgroundColor: "rgba(0,123,255,0)", backgroundImage: "url(../../img/UI_Icon_ArrowLeft.png)", backgroundPosition: "center",
                                    backgroundSize: "contain", backgroundRepeat: "no-repeat", padding: "0px"
                                }} >
                            </button>
                        </Link>
                    </div>
                    <div className="d-flex d-xl-flex align-items-center align-items-xl-center" style={{ height: "100%", width: "75%", paddingLeft: "1%", backgroundColor: "#ffffff" }}>
                        <label className="d-flex d-xl-flex align-items-center justify-content-xl-start align-items-xl-center" id="div-top-labelpath"
                            style={{
                                width: "100%", height: "80%",
                                color: "rgb(15,15,85)", fontSize: "1.5vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "600", margin: "0px"
                            }}>Class {data.class_no} - {data.subject} - {data.lesson_leadzero}.{data.lesson_name}
                        </label>

                        <div style={{ padding: "3px" }}>
                            
                            <a href={linkboard} target="_blank">
                                <button className="btn" type="button" style={{ width: "100%", height: "100%", fontSize: "1vw", backgroundColor: "#0f0f55", color: "white", fontFamily: "Rajdhani", fontWeight: "bold" }} value="White Board">WhiteBoard</button>
                            </a>
                        </div>
                        {!!this.state.pdfurl && <a target="_blank" href={!!this.state.pdfurl && this.state.pdfurl} style={{ color: "#0F0F55", marginRight: "1%" }}><i className="fa fa-print" style={{ fontSize: "24px" }} /></a>}
                    </div>
                    <div className="d-flex align-items-center" style={{ height: "100%", width: "20%", backgroundColor: "#0f0f55" }}>
                        <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="div-top-labelbtn"
                            style={{ height: "100%", color: "rgb(255,255,255)", fontSize: "1.5vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold", width: "100%", margin: "0px" }}>Introduction
               </label>
               <Dropdown selectValue={this.state.selectValue} filterData={this.state.filterData} setFilteredData={this.onChangeHandle} />
                <Link to="/">
                    <button type="button" className="fas fa-power-off" style={{ color:"#fff",border: "none", backgroundColor: "transparent",/* height:"5%", width: "3%", */ opacity: "1", /* position: "absolute",float:"right", */ right:"0" ,top: "0", fontSize: "1.5vw" }}></button>
                </Link>
                    </div>
                </div>
                <div style={{ height: "93%", padding: "0", border: "0.3vw solid rgb(15,15,85)" }}>
                    <div className="d-flex d-xl-flex align-items-center align-items-xl-center mainbody" id="div-body"
                        style={{ height: "100%", width: "100%", backgroundColor: "#ffffff", backgroundImage: "url(../../img/bg2.jpg)", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", position: "relative" }}>
                        <div className="playarea" id="areadiv1" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "block" }}>

                            {!!this.state.env.INTRO_URL && <div id="div-introimg" style={{
                                width: "100%", height: "100%", backgroundImage: "url(" + this.state.env.INTRO_URL + data.background_image + ")",
                                backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat"
                            }}>
                            </div>}
                        </div>
                        <div className="playarea" id="areadiv2" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "none" }} >
                            <div className="d-flex justify-content-center align-items-center flex-column"id="div-show-video" style={{ width: "100%", height: "100%",marginLeft:"auto",marginRight:"auto" }}>

                                <video controls controlsList="nodownload" id="area-video" width="560" height="315"
                                    style={{ width: "100%", height: "100%" }}
                                    src={!!this.state.env.VIDEO_URL && this.state.env.VIDEO_URL + data.video_url}>
                                </video>
                           {/*  <div id="langbar" style={{width:"73%",paddingTop:"0.3%"}} className="d-flex flex-row-reverse">
                                <button className="btn" style={{fontSize: "1vw", backgroundColor: "rgb(15, 15, 85)", color: "white", fontFamily: "Rajdhani",fontWeight: "bold"}} id="telugubtn"onClick={() => this.teluguAudio()}>Telugu</button>
                                <button className="btn" style={{marginLeft:"0.5%",marginRight:"0.5%", fontSize: "1vw", backgroundColor: "rgb(15, 15, 85)", color: "white", fontFamily: "Rajdhani",fontWeight: "bold"}} id="englishbtn"onClick={() => this.englishAudio()}>English</button>
                                <button className="btn" style={{fontSize: "1vw", backgroundColor: "rgb(15, 15, 85)", color: "white", fontFamily: "Rajdhani",fontWeight: "bold"}} id="hindibtn"onClick={() => this.hindiAudio()}>Hindi</button>
                            </div> */}
                            </div>
                        </div>
                        <div className="playarea" id="areadiv3" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "none" }}>
                            {/* <div className="embed-responsive" id="area-mindmap" style={{ width: "100%", height: "100%" }}> */}
                                
                                {
                                    data.mindmap_url != '' && !!data.mindmap_url ? <MindMap dataSource={!!data.mindmap_url && data.mindmap_url}></MindMap> : ''
                                }
                                
                            {/* </div> */}
                        </div>
                        <div className="playarea" id="areadiv4" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "none" }}>
                            <div className="embed-responsive embed-responsive-1by1" id="area-lexicon" style={{ width: "100%", height: "100%" }}>
                                <div className="table-responsive text-center embed-responsive-item">
                                    <table className="table table-bordered table-condensed table-striped" style={{ border: "2px solid #0f0f55", fontSize: "1.4vw", fontFamily: "Rajdhani", fontWeight: "600" }}>
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th>Word</th>
                                                <th>Image</th>
                                                <th>Meaning</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {
                                                !!this.state.lexicon && this.state.lexicon.map((lexicon, index) => {
                                                    return (<tr key={index}>
                                                        <td class="d-flex flex-row" style={{}}>{lexicon.keyword}
                                                        </td>
                                                        <td>
                                                            {
                                                                this.state.subject != 'mathematics' && !!lexicon.image_name &&
                                                                <button className="btn" id="btnimg"
                                                                    style={{
                                                                        marginLeft: "5%",
                                                                        backgroundImage: "url(../../img/imgicon.png)",
                                                                        backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                                                        display: "block"
                                                                    }} type="button" onClick={() => this.modelImage(lexicon.image_name)}>
                                                                </button>
                                                            }
                                                        </td>
                                                        <td style={{}}>{lexicon.description}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className="playarea" id="areadiv5" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "none" }}>
                            <div className="embed-responsive" id="area-activity" style={{ width: "100%", height: "100%" }}>
                                <iframe className="embed-responsive-item reload" style={{ width: "100%", height: "100%" }} src={!!this.state.env.ACTIVITY_URL && this.state.env.ACTIVITY_URL + data.activity_url} allowFullScreen="true"></iframe>
                            </div>
                           {/*  <button class="fas fa-expand expandframe" type="button" style={{ border: "none", backgroundColor: "transparent", width: "3%", height: "6%", opacity: "1", position: "relative", float: "right", marginRight: "-3%", bottom: "6%", fontSize: "1.5vw" }} onClick={() => this.fullscreen()}></button> */}
                           {/* <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenSL" type="button" onClick={()=>this.toggleFullscreen()}></button> */}
                        </div>
                        <div className="playarea" id="areadiv6" style={{ width: "95%", height: "100%", padding: "1%", position: "absolute", marginLeft: "2%", display: "none" }}>
                            <div className="d-flex d-xl-flex flex-column justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="area-ct"
                                style={{ width: "100%", height: "100%", marginRight: "auto", marginLeft: "auto" }}>
                                {

                                    Object.entries(this.state.questions).map(([keyName, value]) => {
                                        return (<label key={keyName}
                                            className="d-flex d-xl-flex justify-content-start align-items-center justify-content-xl-start align-items-xl-center"
                                            id="ct-question-1"
                                            style={{ width: "80%", height: "8%", margin: "0px", fontSize: "1.5vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold", marginLeft: "2%" }}>
                                            {(keyName.replace('question', '')) + '. ' + value}
                                        </label>)

                                    })
                                }
                            </div>
                        </div>
                        <div className="d-flex d-xl-flex flex-column justify-content-center align-items-center justify-content-xl-center align-items-xl-center" id="div-body-left"
                            style={{ width: "10%", height: "100%" }}>
                            <button className="btn btn-primary d-flex d-xl-flex align-items-center align-items-xl-center btnslider" id="div-btn-video" type="button"
                                style={{ width: "100%", height: "5%", padding: "0px", marginBottom: "3%", marginLeft: "-165%", backgroundColor: "rgb(15,15,85)", transition: "0.5s ease" }} onClick={() => this.showDiv('areadiv2')}>
                                <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
                                    style={{ width: "80%", height: "100%", margin: "0px", fontSize: "1vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold" }}>Video
                   </label>
                                <img style={{
                                    width: "20%", height: "100%", backgroundImage: "url(../../img/UI_Icon_FilmPlay.png)", backgroundPosition: "center", backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat"
                                }} />
                            </button>
                            <button className="btn btn-primary d-flex d-xl-flex align-items-center align-items-xl-center btnslider" id="div-btn-mindmap" type="button"
                                style={{ width: "100%", height: "5%", padding: "0px", marginBottom: "3%", marginLeft: "-165%", backgroundColor: "rgb(15,15,85)", transition: "0.5s ease" }} onClick={() => this.showDiv('areadiv3')}>
                                <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
                                    style={{ width: "80%", height: "100%", margin: "0px", fontSize: "1vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold" }}>Mindmap
                   </label>
                                <img style={{
                                    width: "20%", height: "100%", backgroundImage: "url(../../img/UI_Icon_Globe.png)", backgroundPosition: "center", backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat"
                                }} />
                            </button>
                            <button className="btn btn-primary d-flex d-xl-flex align-items-center align-items-xl-center btnslider" name="lexiconbtn" id="div-btn-lexicon" type="button"
                                style={{ width: "100%", height: "5%", padding: "0px", marginBottom: "3%", marginLeft: "-165%", backgroundColor: "rgb(15,15,85)", transition: "0.5s ease" }} onClick={() => this.showDiv('areadiv4')}>
                                <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
                                    style={{ width: "80%", height: "100%", margin: "0px", fontSize: "1vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold" }}>Lexicon
                   </label>
                                <img style={{
                                    width: "20%", height: "100%", backgroundImage: "url(../../img/UI_Icon_Text.png)", backgroundPosition: "center", backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat"
                                }} />
                            </button>
                            <button className="btn btn-primary d-flex d-xl-flex align-items-center align-items-xl-center btnslider" id="div-btn-activity" type="button"
                                style={{ width: "100%", height: "5%", padding: "0px", marginBottom: "3%", marginLeft: "-165%", backgroundColor: "rgb(15,15,85)", transition: "0.5s ease" }} onClick={() => this.showDiv('areadiv5')}>
                                <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
                                    style={{ width: "80%", height: "100%", margin: "0px", fontSize: "1vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold" }}>Activity
                   </label>
                                <img style={{
                                    width: "20%", height: "100%", backgroundImage: "url(../../img/UI_Icon_InputKeyboard.png)", backgroundPosition: "center",
                                    backgroundSize: "contain", backgroundRepeat: "no-repeat"
                                }} />
                            </button>
                            <button className="btn btn-primary d-flex d-xl-flex align-items-center align-items-xl-center btnslider" id="div-btn-ct" type="button"
                                style={{ width: "100%", height: "5%", padding: "0px", marginBottom: "3%", marginLeft: "-165%", backgroundColor: "rgb(15,15,85)", transition: "0.5s ease" }} onClick={() => this.showDiv('areadiv6')}>
                                <label className="d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center"
                                    style={{ width: "80%", height: "100%", margin: "0px", fontSize: "1vw", fontFamily: "Rajdhani, sans-serif", fontWeight: "bold" }}>Critical Thinking
                   </label>
                                <img style={{
                                    width: "20%", height: "100%", backgroundImage: "url(../../img/UI_Icon_Book.png)", backgroundPosition: "center", backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat"
                                }} />
                            </button>
                        </div>
                    </div>
                </div>

                {/* The Modal */}
                <div id="myModal" class="modal" style={{ display: this.state.model }}>
                    <div class="modal-content justify-content-start align-items-center" style={{ width: "60%", height: "80%", border: "none", boxShadow: "none", backgroundColor: "transparent" }}>
                        <div class="modal-header" style={{ width: "84%", height: "7%", position: "absolute", border: "none", zIndex: "+999", padding: "0px", backgroundColor: "transparent" }}>
                            <span style={{ fontSize: "2vw" }} class="close" onClick={() => this.closeModel()}>&times;</span>
                        </div>
                        <div class="modal-body" style={{ backgroundImage: "url(" + this.state.lesson_image + ")", width: "100%", height: "100%", backgroundPosition: "top", backgroundSize: "contain", position: "absolute", padding: "0px", backgroundRepeat: "no-repeat" }}>

                        </div>
                    </div>
                </div>
                 <button id="fullscreenicon" className="fas fa-expand expandframe fullScreenSL" type="button" onClick={()=>this.toggleFullscreen()}></button> 
            </div >
        )
    }
}
// Reusable Dropdown
class Dropdown extends React.Component {
  

    // selectValue => current selected value
    // filterData => choices in the dropdown
    // setFilteredData => function to change the selectValue
  
    handleChange = e => {
      this.props.setFilteredData(e.target.value);
    };
    render() {
    const { selectValue, filterData } = this.props;
      
      return (
      <div className='ddown' id="ddownid" style={{display:"none"}}>
        <select value={selectValue} onChange={this.handleChange} style={{fontWeight:"bold", color:"#0f0f55"}}>
          <option hidden>English</option>
          {filterData.map(data => {
            return (
              <option key={data} value={data}>
                {data}
              </option>
            );
          })}
        </select>
      </div>
    );
    }
    
  };
const mapStateToProps = (state) => ({ //Maps state to redux store as props
    button: state.ui.button,
    authState: state.auth,
    isAuthenticated: state.auth.isAuthenticated
});
function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
}
export default connect(mapStateToProps, { logout, buttonReset })(SelectedLesson);
